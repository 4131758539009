import React, { useState, useEffect } from 'react';
import * as dataManager from '../ContractsInteraction/dataManager.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExternalLink, faXmark } from '@fortawesome/free-solid-svg-icons';
import { copyToClipboard, getShortString } from '../ContractsInteraction/utils/utils.js';
import { IconButton } from '../Components/IconButton.js';
import ProfileModal from '../Components/ProfileModal';
const scanUrl = process.env.REACT_APP_SCAN_URL;
const DISPLAY_LIMIT = 100;

function DataManager() {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [displayLimit, setDisplayLimit] = useState(DISPLAY_LIMIT);

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const { total } = await dataManager.getPaginatedSorted(0, 1);
                const { users: allUsers } = await dataManager.getPaginatedSorted(0, total);
                setTotalUsers(total);
                setUsers(allUsers);
                setFilteredUsers(allUsers);
            } catch (err) {
                console.error('Failed to fetch users:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    const handleSearch = (value) => {
        setSearchTerm(value);
        
        if (!value.trim()) {
            setFilteredUsers(users);
            setDisplayLimit(DISPLAY_LIMIT);
            return;
        }

        const searchTermLower = value.toLowerCase().trim();
        const filtered = users.filter(user => {
            const addressLower = user.accountAddress.toLowerCase();
            const userNameLower = user.userName.toLowerCase();
            const userIdLower = user.userId.toLowerCase();
            
            return addressLower.includes(searchTermLower) || 
                   userNameLower.includes(searchTermLower) || 
                   userIdLower.includes(searchTermLower);
        });
        setFilteredUsers(filtered);
        setDisplayLimit(DISPLAY_LIMIT);
    };

    const clearSearch = () => {
        setSearchTerm('');
        setFilteredUsers(users);
        setDisplayLimit(DISPLAY_LIMIT);
    };

    const loadMore = () => {
        setDisplayLimit(prev => prev + DISPLAY_LIMIT);
    };

    const displayedUsers = filteredUsers.slice(0, displayLimit);
    const hasMore = displayLimit < filteredUsers.length;

    return (
        <div className="user-profile">
            <div className="header">
                <div className="title-section">
                    <h1>Acces Users</h1>
                    {totalUsers !== 0 && <span>Total users: {totalUsers}</span>}
                </div>
                
                <div className="search-container">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => handleSearch(e.target.value)}
                        placeholder="Search by address or username..."
                        className="search-input"
                    />
                    <button onClick={clearSearch} className="clear-button"><FontAwesomeIcon icon={faXmark} /></button>
                </div>
            </div>

            <div className="table-container">
                <table className="profile-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Account</th>
                            <th>User ID</th>
                            <th>User Name</th>
                            <th>Total XP</th>
                            <th>Referral XP</th>
                            <th>Referees</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedUsers.map((user, index) => (
                            <tr key={index}>
                                <td style={{minWidth:'30px'}} className="label">{index + 1}</td>
                                <td className="value">
                                    <IconButton icon={faCopy} onClick={() => copyToClipboard(user.accountAddress)} />
                                    <IconButton icon={faExternalLink} onClick={() => window.open(`${scanUrl}address/${user.accountAddress}`, '_blank')} />
                                    {getShortString(user.accountAddress)}
                                </td>
                                <td className="value">
                                    <IconButton icon={faCopy} onClick={() => copyToClipboard(user.userId)} />
                                    <IconButton icon={faExternalLink} onClick={() => setIsProfileModalOpen(true) || setSelectedUserId(user.userId)} />
                                    {getShortString(user.userId)}
                                </td>
                                <td className="value">
                                    <IconButton icon={faCopy} onClick={() => copyToClipboard(user.userName)} />
                                    {user.userName}
                                </td>
                                <td className="value">{user.totalXP.toString()}</td>
                                <td className="value">{user.referralXP.toString()}</td>
                                <td className="value">{user.refereesCount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {loading && <p>Loading...</p>}
                {hasMore && !loading && (
                    <button 
                        onClick={loadMore} 
                        className="load-more-button"
                        style={{ width: 'auto', margin: '20px auto' }}
                    >
                        Load More
                    </button>
                )}
            </div>

            {isProfileModalOpen && (
                <ProfileModal
                    userAddress={selectedUserId}
                    isOpen={isProfileModalOpen}
                    onClose={() => {
                        setIsProfileModalOpen(false);
                        setSelectedUserId(null);
                    }}
                />
            )}
        </div>
    );
}

export default DataManager;
