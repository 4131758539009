import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import { getUserTransactionsViaLogs } from '../ContractsInteraction/xpTransactions.js';
import { getUserProfile } from '../ContractsInteraction/profile.js';
import { accountAddressById } from '../ContractsInteraction/utils/contractUtils.js';
import { copyToClipboard, getShortString } from '../ContractsInteraction/utils/utils.js';
import { IconButton } from './IconButton.js';
import { faCopy, faExternalLink } from '@fortawesome/free-solid-svg-icons';

function ProfileModal({ userAddress, isOpen, onClose }) {
  const [transactions, setTransactions] = useState([]);
  const [isTransactionsOpen, setIsTransactionsOpen] = useState(false);
  const [accountAddress, setAccountAddress] = useState("");
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const scanUrl = process.env.REACT_APP_SCAN_URL;

  useEffect(() => {
    if (userAddress) {
      const fetchAccountAddress = async () => {
        const address = await accountAddressById(userAddress);
        setAccountAddress(address);
      };
      fetchAccountAddress();
    }
  }, [userAddress]);

  useEffect(() => {
    if (accountAddress) {
      const retrieveProfile = async () => {
        const profile = await getUserProfile(accountAddress);
        setProfileData(profile);
      };
      retrieveProfile();
    }
  }, [accountAddress]);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const txs = await getUserTransactionsViaLogs(accountAddress);
      setTransactions(txs);
    } catch (err) {
      console.error('Error fetching transactions:', err);
    } finally {
      setLoading(false);
    }
  };

  const openTransactionsModal = async () => {
    setIsTransactionsOpen(true);
    await fetchTransactions();
  };

  if (!profileData) {
    return (
      <Modal title="Loading Profile" isOpen={isOpen} onClose={onClose} width='1000px'>
        <h5>Loading...</h5>
      </Modal>
    );
  }
  return (
    <Modal title={profileData.username} isOpen={isOpen} onClose={onClose} width='800px'>
      <div className="profile-modal-content">
        <table className="profile-modal-table">
          <tbody>
            <tr>
              <td className="label">UserId</td>
              <td className="value">
                <IconButton icon={faCopy} onClick={() => copyToClipboard(profileData.userId.toString())} />
                {getShortString(profileData.userId.toString(), 6, 8)}
              </td>
            </tr>
            <tr>
              <td className="label">User Account</td>
              <td className="value">
                <IconButton icon={faCopy} onClick={() => copyToClipboard(accountAddress)} />
                {getShortString(accountAddress, 6, 8)}
              </td>
            </tr>
            <tr>
              <td className="label">XP</td>
              <td className="value">{profileData.xp.toString()}</td>
            </tr>
            <tr>
              <td className="label">Referral XP</td>
              <td className="value">{profileData.refXp.toString()}</td>
            </tr>
            <tr>
              <td className="label">Level</td>
              <td className="value">{profileData.level.toString()}</td>
            </tr>
            <tr>
              <td className="label">Badge</td>
              <td className="value">{profileData.badge.toString()}</td>
            </tr>
          </tbody>
        </table>
        <button className="view-transactions-btn" onClick={openTransactionsModal}>
          View XP Transactions
        </button>
      </div>

      {isTransactionsOpen && (
        <Modal
          title="XP Transactions"
          isOpen={isTransactionsOpen}
          onClose={() => setIsTransactionsOpen(false)}
          width="800px"
        >
          {loading ? (
            <h5>Retrieving transactions...</h5>
          ) : transactions.length > 0 ? (
            <div className="profile-modal-content">
              <div className="xp-stats-container">
                <div className="xp-stat-item">
                  <div className="xp-stat-label">Total Games</div>
                  <div className="xp-stat-value">{transactions.length}</div>
                </div>
                <div className="xp-stat-item">
                  <div className="xp-stat-label">XP</div>
                  <div className="xp-stat-value">{transactions.reduce((total, tx) => total + tx.amount, 0)}</div>
                </div>
                <div className="xp-stat-item">
                  <div className="xp-stat-label">Referral XP</div>
                  <div className="xp-stat-value">{profileData.refXp}</div>
                </div>
                <div className="xp-stat-item">
                  <div className="xp-stat-label">Total XP</div>
                  <div className="xp-stat-value">
                    {transactions.reduce((total, tx) => total + tx.amount, 0) + profileData.refXp}
                  </div>
                </div>
              </div>

              <div className="transactions-table-container">
                <table className="transactions-table">
                  <thead style={{ top: '0px' }}>
                    <tr>
                      <th>#</th>
                      <th>Hash</th>
                      <th>Block</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions.map((tx, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <IconButton
                            icon={faExternalLink}
                            onClick={() => window.open(`${scanUrl}tx/${tx.hash}`, '_blank')}
                          />
                          {getShortString(tx.hash)}
                        </td>
                        <td>
                          <IconButton
                            icon={faExternalLink}
                            onClick={() => window.open(`${scanUrl}block/${tx.block}`, '_blank')}
                          />
                          {tx.block}
                        </td>
                        <td>{tx.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <p>No transactions found.</p>
          )}
        </Modal>
      )}
    </Modal>
  );
}

export default ProfileModal;
