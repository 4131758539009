import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import PasswordPrompt, { correctPassword } from './Components/PasswordPrompt';  // Import the PasswordPrompt component
import Profile from './pages/Profile';
import DataManager from './pages/DataManager';
import { contractsReady } from './ContractsInteraction/contracts';
import { Loading } from './Components/Loading';

function App() {
  const [isReady, setIsReady] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkStatus = async () => {
      const initialized = await contractsReady;
      setIsReady(initialized);
    };
    checkStatus();
    
    // Check if the user has already authenticated on this device
    const storedPassword = localStorage.getItem('password');
    if (false && storedPassword === correctPassword) {
      setIsAuthenticated(true);
    }
  }, []);

  const handlePasswordSubmit = (isAuthenticated) => {
    setIsAuthenticated(isAuthenticated);
  };

  if (!isAuthenticated) {
    return <PasswordPrompt onSubmit={handlePasswordSubmit} />;
  }

  if (!isReady) {
    return <Loading />;
  }

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<DataManager />} />
      <Route path="/data-manager" element={<DataManager />} />
      <Route path="/leaderboard" element={<DataManager />} />
      <Route path="/profile" element={<Profile />} />
    </Routes>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
